import React, { useState } from 'react';
import styled from 'styled-components';
import fetch from '../../../utils/fetch';
import Card from '../../Card/Card';
import TextBox from './TextBox';
import { Heading1, Paragraph } from '../../Typography';
import Notification from '../../Notification';
import { navigate } from 'gatsby';
import Button from '../../Button';
import Link from '../../Link';
import { Input } from '../../Forms';
import Flexbox from '../../Flexbox';

const ValidateForm = () => {
    const [jacobsRef, setJacobsRef] = useState('');
    const [clientRef, setClientRef] = useState('');
    const [postcode, setPostcode] = useState('');
    const [error, setError] = useState(false);

    const submit = async (e) => {
        e.preventDefault();
        try {
            await fetch.get('/api/csrf-cookie');

            await fetch.post('/api/client-login', {
                jacobs_ref: jacobsRef,
                client_ref: clientRef,
                postcode: postcode,
            });

            if (window) {
                navigate('/web-services/payment-plan-account', {
                    state: {
                        authenticated: true,
                    },
                    replace: true,
                });
            }
        } catch (error) {
            if(!error.response){
                return setError('Unable to connect with server. Please try again, if this continues please contact us.');
            }
            if(error.response.status===500){
                return setError('Server error. Please try again, if this continues please contact us.');
            }  
            if(error.response.status===403){
                return setError('Credentials not recognised, your council does not currently support this feature.');
            }
            setError('Credentials not recognised.');
        }
    };

    return (
        <>
            <TextBox
                heading='Set up a three month payment plan.'
                body='Repay your outstanding debt via either weekly or monthly
                repayments. To get started, validate your account by entering
                your Jacobs and Council Reference numbers.'
            />
            <Card>
                <Container>
                    <Heading1 textAlign='center'>
                        Validate Your Account
                    </Heading1>
                    <Paragraph textAlign='center'>
                        Please enter your Jacobs reference, Client reference and
                        postcode to continue.
                    </Paragraph>
                    <form onSubmit={submit}>
                        <Input
                            id='jacobs-ref'
                            type='text'
                            onChange={(e) => setJacobsRef(e.target.value)}
                            required
                            name='jacobs-ref'
                            label='Jacobs reference'
                        />
                        <Input
                            id='client-ref'
                            type='text'
                            onChange={(e) => setClientRef(e.target.value)}
                            required
                            name='client-ref'
                            label='Client reference'
                        />
                        <Input
                            id='postcode'
                            type='text'
                            onChange={(e) => setPostcode(e.target.value)}
                            required
                            name='postcode'
                            label='Postcode'
                        />
                        <Button type='submit' mt={40} width='100%' height={40}>
                            Validate
                        </Button>
                        {error && (
                            <Notification
                                padding='8px'
                                marginTop='20px'
                                textAlign='center'
                            >
                                {error}
                            </Notification>
                        )}
                        <Flexbox justifyContent='center' mt={24}>
                            <Link href='/contact' fontSize={14}>
                                Having trouble validating your account?
                            </Link>
                        </Flexbox>
                    </form>
                </Container>
            </Card>
        </>
    );
};

export default ValidateForm;

const Container = styled.div`
    padding: 40px;
    @media (max-width: 576px) {
        padding: 24px 16px;
    }
`;
