import React from 'react';
import styled from 'styled-components';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import PaymentPlan from '../../components/PaymentPlan';

const Container = styled.div`
    display: flex;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
    @media (max-width: 992px) {
        display: block;
    }
`;

const ThreeMonthArrangements = () => {
    return (
        <>
            <HeroSection />
            <Section
                backgroundColor='grey'
                paddingTop='74px'
                paddingBottom='80px'
            >
                <Container>
                    <PaymentPlan />
                </Container>
            </Section>
        </>
    );
};

export default ThreeMonthArrangements;
